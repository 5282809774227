<template>
  <div v-if="itemsFiltered">
    <draggable
      v-model="itemsFiltered"
      :draggable="'.itemDraggable' + parentID"
      style="width:100%;"
      :animation="200"
      group="description"
      ghostClass="ghost"
      :disabled="!((activeDragable === parentID || activeDragable === '-1') && !isSearchResult)"
      @start="onSortStart(parentID)"
      @end="onSortItems(table, itemsFiltered)"
    >
    <div style="cursor: pointer;" 
      v-for="item in itemsFiltered" 
      :key="item.ID + JSON.stringify(item.Name)"
      v-bind:class="'itemDraggable' + parentID"
    >
        <v-toolbar
          @click="handleExpand(item)"
          :color="colors[item.Level]"
          elevation="1"
          :style="{ 'padding-left' : `${item.Level * 20}px`}"
        >
          <!-- <v-app-bar-nav-icon>mdi-lead-pencil</v-app-bar-nav-icon> -->
          <v-icon small style="margin-right: 10px;">mdi-cursor-move</v-icon>
          <v-icon v-if="item.type === 'folder'" small style="margin-right: 10px;font-size:20px">
            {{ item.Visibility === 'public' ? 'mdi-folder-star' 
            : item.Visibility === 'privateAllUser' ? 'mdi-folder-key' 
            : item.CustomerSec ? 'mdi-folder-account' : 'mdi-folder' }}
          </v-icon>
          <!--v-icon v-if="item.type === 'folder'" small style="margin-right: 10px;font-size:20px">{{ item.CustomerSec ? 'mdi-folder-account' : 'mdi-folder'}}</v-icon-->
          <v-icon v-else-if="item.type === 'file'" small style="margin-right: 10px;">mdi-file</v-icon>
          <v-toolbar-title>
            {{ getTranslation(item.Name, locale) }}
          </v-toolbar-title>
          <v-spacer />
          <show-pdf v-if="item.type === 'file'" :id="item.ID" />
          <add-subitem
            v-if="item.type === 'folder' && onAddSubitem"
            :item="item"
            :onAddSubitem="onAddSubitem"
            :locale="locale"
          />          
          <v-btn
            v-if="onEditItem"
            icon
            @click.stop.prevent="onEditItem(item)"
          >
            <v-icon x-small>mdi-pencil</v-icon>
          </v-btn>

          <delete-item
            v-if="item.ID && onDeleteItem"
            :id="item.ID"
            :onDeleteItem="onDeleteItem"
            icon="mdi-delete"
            :locale="locale"
          />
          <v-btn
            v-if="item.type !== 'file'"
            icon
            @click.stop.prevent="handleExpand(item)"
            :disabled="item.type!=='file' && !item.CountFile && !item.CountFolder"
          >
            <v-icon>{{ !item.expand ? 'mdi-menu-down' : 'mdi-menu-up'}}</v-icon>
          </v-btn>
          <v-btn
            v-else-if="item.type === 'file'"
            icon
          >
            <v-icon x-small>mdi-file</v-icon>
          </v-btn>
        </v-toolbar>
        <div v-show="item.expand">
          <!-- <pdf v-if="item.URL" :data="item" /> -->
          <draggable-table 
            :data="items" 
            :parentID="item.ID" 
            :onGetData="onGetData"
            :onCancelEdit="onCancelEdit"
            :onShowMore="onShowMore"
            :onSortItems="onSortItems"
            :onSortStart="onSortStart"
            :activeDragable="activeDragable"
            :onEditItem="onEditItem"
            :onDeleteItem="onDeleteItem"
            :onAddSubitem="onAddSubitem"
            :count="item.ItemsCount"
            :table="item.CountFile ? 'file' : 'folder'"
            :level="subLevel"
            :isSearchResult="isSearchResult"
          />
        </div>
        
      </div>
    </draggable>
    <v-toolbar
      v-if="itemsFiltered.length < count"
      @click="onShowMore(parentID)"
      elevation="1"
      class="showMore"
      style="cursor: pointer;"
      :color="colors[level]"
    >
      <v-toolbar-title>
        <v-btn
          color="primary"
          text
          @click="onShowMore(parentID)"
          style="pad"
          :style="{ 'margin-left': `${level*10}px` }"
        >
          <span style="font-size: 10px">{{ itemsFiltered.length }} {{ $t('common.of', locale) }} {{ count }} [{{ $t('common.showMore', locale).toUpperCase() }}]</span>
        </v-btn>
      </v-toolbar-title>      
    </v-toolbar>
  </div>
</template>
<script>
  import { mapState } from 'vuex'
  import draggable from 'vuedraggable'
  import utils from '@/services/utils'
  import DeleteItem from '@/components/DeleteItem'
  // import pdf from '@/components/PDF'
  import DraggableTable from './Index'
  import AddSubitem from './AddSubitem'
  import ShowPdf from './ShowPdf'
  export default {
    name: 'draggable-table',
    components: {
      draggable,
      DraggableTable,
      DeleteItem,
      // pdf,
      AddSubitem,
      ShowPdf,
    },
    props: {
      parentID: {
        type: String,
        required: false,
        default: null
      },
      activeDragable: {
        type: String,
        required: false,
        default: null
      },
      data: {
        type: Array,
        required: true
      },
      count: {
        type: Number,
        required: false,
        default: null
      },
      onCancelEdit: {
        type: Function,
        required: true
      },
      onShowMore: {
        type: Function,
        required: true
      },
      onSortItems: {
        type: Function,
        required: false,
        default: null
      },
      onSortStart: {
        type: Function,
        required: false,
        default: null
      },
      onEditItem: {
        type: Function,
        required: false,
        default: null
      },
      onDeleteItem: {
        type: Function,
        required: false,
        default: null
      },
      onAddSubitem: {
        type: Function,
        required: false,
        default: null
      },
      onGetData: {
        type: Function,
        required: false,
        default: null
      },
      table: {
        type: String,
        required: true
      },
      level: {
        type: Number,
        default: 0
      },
      isSearchResult: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        colors: ['#FFFFFF', '#FAFAFA', '#F5F5F5', '#EEEEEE', '#E0E0E0', '#BDBDBD'],
        items: null,
        itemsFiltered: null,
      }
    },
    computed: {        
      ...mapState('app', ['locale']),
      subLevel () {
        return this.level + 1
      },
    },
    watch:{
      data (v) {
        this.items = v
        this.itemsFiltered = this.items.filter(x => x.ParentFolderID === this.parentID)
      },
      
    },
    mounted () {
      this.items = this.data
      this.itemsFiltered = this.items.filter(x => x.ParentFolderID === this.parentID)
    },
    methods: {
      getTranslation (value, locale) {
        return utils.getTranslation(value, locale)
      },
      handleDelete () {
        
      },
      handleExpand (item) {
        this.onCancelEdit()
        // if(item.type === 'file') item.expand = !item.expand
        if (item.CountFile || item.CountFolder) {                    
          if (!item.ItemsCount) this.onGetData(item.ID)
          item.expand = !item.expand
        }        
      },
    }
  }
</script>
